export const ENV_VARS_KUBE = {
  VUE_APP_ENV: "preprod",
  VUE_APP_IAM_REALM: "swascan",
  VUE_APP_IAM_CLIENT_ID: "swascan",
  VUE_APP_I18N_LOCALE: "it",
  VUE_APP_I18N_FALLBACK_LOCALE: "it",
  VUE_APP_NAME: "frontend",
  VUE_APP_OLD_PLATFORM: "https://security-preprod.swascan.com/",
  VUE_APP_RESET_PASSWORD: "https://security-preprod.swascan.com/accounts/password/reset/",
  VUE_APP_SIGN_UP: "https://security-preprod.swascan.com/accounts/signup/",
  VUE_APP_PERMITTED_LICENSES: "dti,nscan,phishing_attack,smishing_attack,cti,cyber_threat,tvm,dti_lite,rri,webapp,cardp,ir",
  VUE_APP_IAM_BASE_URL: "https://iam.platform-preprod.swascan.com/auth",
  VUE_APP_API_BASE_URL: "https://core.platform-preprod.swascan.com/core",
  VUE_APP_BACKEND_BASE_URL: "https://backend.platform-preprod.swascan.com",
  VUE_APP_LICENSE_BASE_URL: "https://licensing.platform-preprod.swascan.com/licensing",
  VUE_APP_REST_BASE_URL: "https://rest.platform-preprod.swascan.com",
  VUE_APP_SWASCAN_INFO_EMAIL: "mailto:site@swascan.com",
  VUE_APP_VULNERABILITY_EARLY_WARNING_ENV_KEY: "PREPROD",
  VUE_APP_FORCE_KEYCLOAK: "false",
  VUE_APP_FORCE_STANDALONE: "false",
};
