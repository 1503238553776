<template>
    <div class="layout-footer">
        Powered by
        <a href="https://www.swascan.com/" target="_blank">Swascan s.r.l.</a>
        | <a href="mailto:info@swascan.com">info@swascan.com</a> |
        <a :href="$t('general.policy_privacy_link')" target="_blank">Privacy</a> |
        <a :href="$t('footer.terms_and_conditions_link')" target="_blank">{{
            $t("footer.terms_and_conditions")
        }}</a> |
        <router-link :to="{ name: 'security-measures' }"
            tag="a">{{ $t("pages.security_measures.title") }}</router-link>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
};
</script>

<style scoped>
</style>